import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { environment } from 'apps/mba-cpr-survey-portal/src/environments/environment';
import { BehaviorSubject, lastValueFrom } from 'rxjs';

@Injectable()
export class NotificationsService {
  private readonly urlPrefix = `${environment.cprServiceApi}notification`;

  unreadCount = signal(0);

  interval: NodeJS.Timeout; // actually a browser timeout

  constructor(
    auth: AngularFireAuth,
    private readonly http: HttpClient,
  ) {
    auth.user.subscribe((user) => {
      if (user) {
        this.refreshUnreadCount();
        this.interval = setInterval(() => this.refreshUnreadCount(), 30000);
      } else {
        clearInterval(this.interval);
      }
    });
  }

  async refreshUnreadCount() {
    const updatedCount = await lastValueFrom(
      this.http.get<number>(`${this.urlPrefix}/count-unread`),
    );
    this.unreadCount.set(updatedCount);
  }

  getNotifications(page = 0) {
    return lastValueFrom(this.http.get<any>(`${this.urlPrefix}/page/${page}`));
  }

  setStatus(_id, status) {
    switch (status) {
      case 'read':
        this.unreadCount.update((value) => value - 1);
        break;
      case 'unread':
        this.unreadCount.update((value) => value + 1);
        break;
    }
    return lastValueFrom(this.http.post(`${this.urlPrefix}/user/set-status`, { _id, status }));
  }

  markAllRead() {
    return lastValueFrom(this.http.patch(`${this.urlPrefix}/mark-all-read`, {}));
  }
}
