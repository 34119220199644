<app-header>
  <p>{{ notificationsService.unreadCount() }} Unread notifications.</p>
  @if (!notificationsService.unreadCount()) {
    <button mat-flat-button (click)="markAllRead()">Mark all as read</button>
  }
  <button mat-flat-button (click)="reload()">Refresh</button>
</app-header>

@if (notifications.length) {
  <mat-card class="margin">
    <mat-card-content>
      @for (notification of notifications; track notification.id) {
        <app-notifications-notification [notification]="notification" />
      }
    </mat-card-content>
  </mat-card>
}

@if (hasMore) {
  <button mat-button (click)="showMore()" class="margin" style="width: 100%">Show 10 more</button>
}
