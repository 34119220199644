<div class="container">
  <div class="logo-container">
    <img src="/assets/logo/cpr-min.svg" routerLink="/overview" />
  </div>

  <div class="scrollable">
    <suvo-bi-list-navigation [links]="links" showIcons="true" class="links-sidenav">
      <suvo-bi-list-navigation-item [link]="notificationLink()" showIcons="true" />
    </suvo-bi-list-navigation>
  </div>

  <mat-nav-list>
    <app-account-sidenav-widget />
  </mat-nav-list>
</div>
