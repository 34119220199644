import { IOverviewPreferences } from '../interfaces/overview.interfaces';

export const OVERVIEW_TILES = {
  overviewReport: {
    label: 'Overview Report',
    default: true,
  },
  myNotifications: {
    label: 'My Notifications',
    default: true,
  },
  // mySamplesReport: {
  //   label: 'My Samples Report',
  //   default: true,
  // },
  myActiveCheckblocks: {
    label: 'My Active Checkblocks',
    default: true,
  },
  myActiveSamples: {
    label: 'My Active Samples',
    default: true,
  },
};

export const OVERVIEW_DEFAULT_PREFERENCES = Object.entries(OVERVIEW_TILES).reduce(
  (prefs, [key, value]) => {
    prefs[key] = value.default;
    return prefs;
  },
  {} as IOverviewPreferences,
);
