import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reports-screen',
  templateUrl: './reports-screen.component.html',
  styleUrls: ['./reports-screen.component.scss'],
})
export class ReportsScreenComponent {
  constructor() {}
}
