export const chartColors = [
  '#eb601b',
  '#f0a211',
  '#6bbbae',
  '#266f63',
  '#3799a9',
  '#78cae2',
  '#2f4e9d',
  '#201f45',
  '#85548a',
  '#f2919e',
  '#a21a1e',
];
