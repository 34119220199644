import { DateTime } from 'luxon';
import { Component, OnInit } from '@angular/core';
import { IStatsTileDefinition } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/dashboards/interfaces/tiles/definitions/stats-tile-definition.interface';
import { FilterConditionType, FilterOperatorType } from '@suvo-bi-lib';

@Component({
  selector: 'app-monthly-report',
  templateUrl: './monthly-report.component.html',
  styleUrls: ['./monthly-report.component.scss'],
})
export class MonthlyReportComponent implements OnInit {
  constructor() {}

  tileDefinition: IStatsTileDefinition;

  ngOnInit(): void {
    let nowDateTime = DateTime.utc();
    let startOfMonth = nowDateTime.startOf('month');

    this.tileDefinition = {
      tileType: 'stats',
      title: 'This Month',
      statDefinitions: [
        {
          title: 'Samples Finalised',
          recipe: {
            dataSource: 'samples',
            fullRoute: 'sample-reports',
            metric: 'count',
            filters: {
              name: 'Report Filters',
              query: {
                op: FilterOperatorType.And,
                rules: [
                  {
                    key: 'finalisedAt',
                    con: [
                      {
                        type: FilterConditionType.GreaterThanEquals,
                        val: startOfMonth.toISO(),
                      },
                    ],
                  },
                ],
              },
            },
          },
        },
        {
          title: 'Tows Finalised',
          recipe: {
            dataSource: 'tows',
            fullRoute: 'recording-event-reports',
            metric: 'count',
            extraParams: {
              type: 'tow',
            },
            filters: {
              name: 'Report Filters',
              query: {
                op: FilterOperatorType.And,
                rules: [
                  {
                    key: 'finalisedAt',
                    con: [
                      {
                        type: FilterConditionType.GreaterThanEquals,
                        val: startOfMonth.toISO(),
                      },
                    ],
                  },
                ],
              },
            },
          },
        },
      ],
      canView: true,
      backgroundColor: 'white',
    };
  }
}
