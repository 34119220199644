<ul>
  <li>User accounts, permissions and access to the links below are managed by Dan Lear</li>
  <li>Help guides are available in the help section.</li>
  <li>
    The data is based on a snapshot provided at the end of 2023 so any data added since then is not
    included. The data will be updated with a new snapshot soon.
  </li>
  <li>
    Some features are not fully implemented. Please see the task list
    <a href="https://app.asana.com/0/1207298625098936/1207298625098936" target="_blank">here</a>
  </li>
  <li>
    Please give any feedback, report any issues and ask any questions
    <a href="https://app.asana.com/0/1207298625098943/1207298625098943" target="_blank">here</a>
  </li>
  <li>You can access this information again from within the help section.</li>
</ul>
