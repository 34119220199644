import { Component, Inject, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { ISample } from '../../../samples/interface/sample.interface';
import { SamplesService } from '../../../samples/service/samples.service';
import { NOTIFICATION_INPUT } from '../../notifications.type';
import { ISampleAssignedUserNotification } from '../../notification.type';
import { NotificationsService } from '../../notifications.service';

@Component({
  standalone: true,
  imports: [RouterModule, MatButtonModule],
  template: `
    <h3>Sample Assigned</h3>

    <button
      mat-stroked-button
      [routerLink]="['/tows', sample.towId, 'samples', this.notification.metadata.sampleId]"
      (click)="notificationService.setStatus(notification._id, 'read')"
    >
      {{ sample.oldSampleId }}
    </button>
  `,
})
export class SampleAssignedNotificationComponent {
  sample: ISample;

  constructor(
    @Inject(NOTIFICATION_INPUT) readonly notification: ISampleAssignedUserNotification,
    readonly notificationService: NotificationsService,
    samples: SamplesService,
  ) {
    samples
      .getOne('', this.notification.metadata.sampleId)
      .then((sample) => (this.sample = sample));
  }
}
