import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TourDialogComponent } from '../../tour-dialog/tour-dialog.component';

@Component({
  template: `
    <app-header />

    <mat-card>
      <mat-card-header>
        <mat-card-title>User Guides</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        Link to help site:
        <a
          mat-flat-button
          href="https://sites.google.com/suvotechnology.com/cprsurveyhelpsite/home"
          target="_blank"
        >Help site
        </a>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-header>
        <mat-card-title>Beta Test Help</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <app-beta-help-content />
        <button mat-flat-button (click)="showBetaTestInfo()">Show BETA test introduction</button>
      </mat-card-content>
    </mat-card>
  `,
  styles: [
    `
      mat-card {
        margin: var(--global-padding);
        margin-top: 0;
      }
    `,
  ],
})
export class HelpScreenComponent {
  constructor(private readonly dialog: MatDialog) {}

  showBetaTestInfo() {
    this.dialog.open(TourDialogComponent);
  }
}
