<div class="notification-container" [class.unread]="notification.status === 'unread'">
  <div style="flex-grow: 1">
    <span style="color: #929292">{{ notification.createdAt | date: 'medium' : 'UTC' }}</span>
    <ng-container *ngComponentOutlet="component; injector: childInjector" />
  </div>
  @switch (notification.status) {
    @case ('read') {
      <button mat-flat-button (click)="setStatus('unread')">Mark as unread</button>
    }
    @case ('unread') {
      <button mat-flat-button (click)="setStatus('read')">Mark as read</button>
    }
  }
</div>
