import { IBiLink } from '@suvo-bi-lib';

export const NAVIGATION_LINKS: IBiLink[] = [
  {
    route: 'overview',
    displayName: 'Overview',
    displayIcon: 'dashboard',
  },
  {
    route: 'tows',
    displayName: 'Tows',
    displayIcon: 'share',
  },
  {
    route: 'samples',
    displayName: 'Samples',
    displayIcon: 'search',
  },
  {
    route: 'routes',
    displayName: 'Routes',
    displayIcon: 'route',
  },
  {
    route: 'ships',
    displayName: 'Ships',
    displayIcon: 'directions_boat',
  },
  {
    route: 'cprs',
    displayName: 'CPRs',
    displayIcon: 'fax',
  },
  {
    route: 'microscopes',
    displayName: 'Microscopes',
    displayIcon: 'biotech',
  },
  {
    route: 'export',
    displayName: 'Export',
    displayIcon: 'ios_share',
  },
  {
    route: 'reports',
    displayName: 'Reports',
    displayIcon: 'summarize',
  },
  {
    route: 'audit',
    displayName: 'Audit',
    displayIcon: 'query_stats',
  },
  {
    route: 'users',
    displayName: 'Users',
    displayIcon: 'group',
  },
  {
    route: 'knowledge-base',
    displayName: 'Knowledge Base',
    displayIcon: 'school',
  },
  {
    route: 'help',
    displayName: 'Help',
    displayIcon: 'help',
  },
];
