import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../../../shared/shared.module';
import { IRecordingEvent } from '../../../tows/interface/recording-event.interface';
import { TowsService } from '../../../tows/service/tows.service';
import { ITowStatusChangeUserNotification } from '../../notification.type';
import { NotificationsService } from '../../notifications.service';
import { NOTIFICATION_INPUT } from '../../notifications.type';

@Component({
  standalone: true,
  imports: [RouterModule, MatButtonModule, SharedModule],
  template: `
    <h3>Tow Status Changed</h3>

    <button
      mat-stroked-button
      [routerLink]="['/tows', notification.metadata.towId]"
      (click)="notificationService.setStatus(notification._id, 'read')"
    >
      {{ tow.metadata.oldTowBaseId }}
    </button>

    <app-status-chip [value]="notification.metadata.newStatus" />
  `,
})
export class TowStatusChangeNotificationComponent {
  tow: IRecordingEvent<'tow'>;

  constructor(
    @Inject(NOTIFICATION_INPUT) readonly notification: ITowStatusChangeUserNotification,
    readonly notificationService: NotificationsService,
    tows: TowsService,
  ) {
    tows.getOne('', notification.metadata.towId).then((tow) => (this.tow = tow));
  }
}
