<app-header />

<div class="reports-container">
  <div class="report-container">
    <app-overview-report />
  </div>
  <div class="report-container">
    <app-monthly-report />
  </div>
  <div class="report-container">
    <app-analyst-report />
  </div>
</div>
