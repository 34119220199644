<div class="tour-content">
  <div class="tour-content-2">
    <div *ngIf="tourStep === 1">
      <div class="image-container">
        <img class="small-image" src="../../../../assets/logo/cpr-min.svg" />
      </div>
      <div class="text-container">
        <div class="description">
          <h2>Welcome to the CPR Survey Admin Portal BETA Test!</h2>
          <p>
            Please try the different features of the portal and provide your feedback, questions and
            bug reports using the link provided below.
          </p>
          <p>Please read the following before you start testing:</p>
          <app-beta-help-content></app-beta-help-content>
        </div>
      </div>
    </div>
    <div class="button-container">
      <div *ngIf="tourStep < 2"></div>
      <div>
        <button
          mat-flat-button
          color="primary"
          *ngIf="tourStep >= 2"
          (click)="tourStep = tourStep - 1"
        >
          Previous
        </button>
        <button
          mat-flat-button
          color="primary"
          *ngIf="tourStep < 1"
          (click)="tourStep = tourStep + 1"
          class="next-button"
          cdkFocusInitial
        >
          Next
        </button>
        <button
          mat-flat-button
          color="primary"
          *ngIf="tourStep === 1"
          [mat-dialog-close]="null"
          class="next-button"
        >
          Get Started
        </button>
      </div>
    </div>
  </div>
</div>
