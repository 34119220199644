<suvo-bi-navigation-outlet #navigationOutlet [noMenuButton]="true">
  <ng-container mainContent>
    <div fxLayout="column" class="main-content">
      <router-outlet></router-outlet>
      <div class="footer-container">
        <app-footer></app-footer>
      </div>
    </div>
  </ng-container>

  <ng-container leftSidenav>
    <app-main-sidenav></app-main-sidenav>
  </ng-container>
</suvo-bi-navigation-outlet>
