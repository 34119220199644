import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { ISidenavState } from '@suvo-bi-lib';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { CustomerSidenavService } from '../../services/customer-sidenav.service';

@Component({
  selector: 'suvo-bi-navigation-outlet',
  templateUrl: './navigation-outlet.component.html',
  styleUrls: ['./navigation-outlet.component.scss'],
})
export class NavigationOutletComponent implements OnDestroy, AfterViewInit {
  @Input() noMenuButton = false;

  @ViewChild('linksSidenav') sidenav!: MatSidenav;

  public linksSidenavMode: MatDrawerMode = 'side';
  private unsubscribe$ = new Subject<void>();

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private changeDetectorRef: ChangeDetectorRef,
    private customerSidenavService: CustomerSidenavService,
  ) {}

  async ngAfterViewInit() {
    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
      .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
      .subscribe((result) => {
        if (result.matches) {
          this.linksSidenavMode = 'over';

          this.customerSidenavService.close();
        } else {
          this.linksSidenavMode = 'side';

          this.customerSidenavService.open();
        }
        this.changeDetectorRef.detectChanges();
      });

    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe(async (event) => {
      if (event instanceof NavigationEnd && this.linksSidenavMode === 'over') {
        this.sidenav.close();
      }
    });

    this.customerSidenavService.sidenavSubject
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state: ISidenavState) => {
        if (state.open) {
          this.sidenav?.open();
        } else {
          this.sidenav?.close();
        }
      });

    this.sidenav.openedChange.pipe(takeUntil(this.unsubscribe$)).subscribe((sidenavOpen) => {
      this.customerSidenavService.sidenavOpen = sidenavOpen;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
