import { Component, Input } from '@angular/core';
import { FiltersChannel, IItemsTableOptions, ITableDataSource, SessionService } from '@suvo-bi-lib';
import { TableComponent } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/tables/components/table/table.component';
import { IFilters } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/filters/filters.interface';
import { ITablePagination } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/table/table-pagination.interface';
import { ITableSort } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/table/table-sort.interface';
import { AuditTableService } from '../../services/audit-table.service';
import { IAudit } from '../../interfaces/audit.interface';

@Component({
  selector: 'app-audit-table',
  templateUrl: './audit-table.component.html',
  styleUrls: ['./audit-table.component.scss'],
})
export class AuditTableComponent extends TableComponent<IAudit, string> {
  readonly headings = [
    // 'id',
    'firebaseId',
    'path',
    'method',
    'code',
    'createdAt',
  ];

  @Input() filtersChannel?: FiltersChannel;
  @Input() options: IItemsTableOptions;
  @Input() dontCache = false;
  @Input() tableAlias: string;

  pageSize = this.options?.defaultPageSize || 15;
  canSort = false;

  constructor(
    sessionService: SessionService,
    private readonly auditTableService: AuditTableService,
  ) {
    super(sessionService);
  }

  protected async getPaginated(
    filters: IFilters,
    sort: ITableSort,
    pagination: ITablePagination,
    search: string,
  ): Promise<ITableDataSource<IAudit>> {
    const paginated = await this.auditTableService.getPaginated('', {
      filters,
      sort,
      pagination,
      search,
    });

    paginated.data.forEach((item) => (item.path = item.path.split('/api/v1').pop()));

    return paginated;
  }
}
