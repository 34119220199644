import { Component, Injector, Input, OnInit, Type } from '@angular/core';
import { TNotificationType } from '../../notification.type';
import { NotificationsService } from '../../notifications.service';
import { NOTIFICATION_INPUT } from '../../notifications.type';
import { CheckBlockRaisedNotificationComponent } from '../notification-types/check-block-raised-notification.component';
import { SampleAssignedNotificationComponent } from '../notification-types/sample-assigned-notification.component';
import { TowStatusChangeNotificationComponent } from '../notification-types/tow-status-change-notification.component';

const map: Record<TNotificationType, Type<any>> = {
  TowStatusChange: TowStatusChangeNotificationComponent,
  SampleAssigned: SampleAssignedNotificationComponent,
  CheckBlockRaised: CheckBlockRaisedNotificationComponent,
};

@Component({
  selector: 'app-notifications-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  @Input() notification: any;
  status: string;
  component: Type<any>;
  childInjector: Injector;

  constructor(
    private injector: Injector,
    private notificationService: NotificationsService,
  ) {}

  ngOnInit(): void {
    this.childInjector = Injector.create({
      providers: [{ provide: NOTIFICATION_INPUT, useValue: this.notification }],
      parent: this.injector,
    });
    this.component = map[this.notification.type];
  }

  async setStatus(status) {
    await this.notificationService.setStatus(this.notification._id, status);
    this.notification.status = status;
    this.status = status;
  }
}
