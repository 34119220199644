<mat-divider></mat-divider>

<a mat-list-item [routerLink]="'users/' + uid">
  <mat-icon>person</mat-icon>
  <span>{{ displayName }}</span>
</a>

<mat-divider></mat-divider>

<a mat-list-item class="logout-button" (click)="logout()">
  <div class="logout-container">
    <mat-icon>logout</mat-icon>
    <span>Log Out</span>
  </div>
</a>
