import { Component } from '@angular/core';
import { FilterConditionType, FilterOperatorType, IStatsTileDefinition } from '@suvo-bi-lib';

@Component({
  selector: 'app-overview-report',
  template: `<suvo-bi-tile-wrapper class="dashboard-tile" [tileDefinition]="tileDefinition" />`,
})
export class OverviewReportComponent {
  readonly tileDefinition: IStatsTileDefinition = {
    tileType: 'stats',
    title: 'Overview',
    statDefinitions: [
      {
        title: 'Total unfinalised samples in system',
        recipe: {
          dataSource: 'samples',
          fullRoute: 'sample-reports',
          metric: 'count',
          filters: {
            name: 'Report Filters',
            query: {
              op: FilterOperatorType.And,
              rules: [
                {
                  key: 'finalisedAt',
                  con: [
                    {
                      type: FilterConditionType.NotExists,
                    },
                  ],
                },
              ],
            },
          },
        },
      },
      {
        title: 'Total unfinalised tows in system',
        recipe: {
          dataSource: 'tows',
          fullRoute: 'recording-event-reports',
          metric: 'count',
          extraParams: {
            type: 'tow',
          },
          filters: {
            name: 'Report Filters',
            query: {
              op: FilterOperatorType.And,
              rules: [
                {
                  key: 'metadata.finalisedAt',
                  con: [
                    {
                      type: FilterConditionType.NotExists,
                    },
                  ],
                },
              ],
            },
          },
        },
      },
    ],
    canView: true,
    backgroundColor: 'white',
  };
}
