import { NgModule } from '@angular/core';
import { DashboardsModule, DataSourceModule, SmartGridModule } from '@suvo-bi-lib';
import { environment } from 'apps/mba-cpr-survey-portal/src/environments/environment';
import { CommonModule } from '@angular/common';
import { OverviewReportComponent } from './components/overview-report/overview-report.component';
import { MonthlyReportComponent } from './components/monthly-report/monthly-report.component';
import { AnalystReportComponent } from './components/analyst-report/analyst-report.component';
import { MySamplesReportComponent } from './components/my-samples-report /my-samples-report.component';

@NgModule({
  imports: [
    CommonModule,
    DashboardsModule.forRoot({
      dataServiceApi: environment.cprServiceApi,
    }),
    DataSourceModule.forRoot({
      dataServiceApi: environment.cprServiceApi,
    }),
    SmartGridModule,
  ],
  declarations: [
    OverviewReportComponent,
    MonthlyReportComponent,
    AnalystReportComponent,
    MySamplesReportComponent,
  ],
  exports: [
    OverviewReportComponent,
    MonthlyReportComponent,
    AnalystReportComponent,
    MySamplesReportComponent,
  ],
})
export class ReportsComponentsModule {}
