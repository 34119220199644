import { Component } from '@angular/core';

@Component({
  selector: 'app-tour-dialog',
  templateUrl: './tour-dialog.component.html',
  styleUrls: ['./tour-dialog.component.scss'],
})
export class TourDialogComponent {
  tourStep = 1;
}
