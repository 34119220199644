import { Component, computed } from '@angular/core';
import { IBiLink } from '@suvo-bi-lib';
import { NotificationsService } from '../../../notifications/notifications.service';
import { NAVIGATION_LINKS } from './navigation-links.constant';

@Component({
  selector: 'app-main-sidenav',
  templateUrl: './main-sidenav.component.html',
  styleUrl: './main-sidenav.component.scss',
})
export class MainSidenavComponent {
  readonly links = NAVIGATION_LINKS;

  notificationLink = computed<IBiLink>(() => ({
    route: 'notifications',
    displayName: 'Notifications',
    displayIcon: 'notifications',
    badge: this.notificationsService.unreadCount() || undefined,
  }));

  constructor(private readonly notificationsService: NotificationsService) {}
}
