import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-account-sidenav-widget',
  templateUrl: './account-sidenav-widget.component.html',
  styleUrls: ['./account-sidenav-widget.component.scss'],
})
export class AccountSidenavWidgetComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$ = new Subject<boolean>();

  displayName: string;
  uid: string;

  constructor(
    private readonly auth: AngularFireAuth,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.auth.user.pipe(takeUntil(this.unsubscribe$)).subscribe(({ displayName, uid }) => {
      // Display name comes from Microsoft account
      this.displayName = displayName;
      this.uid = uid;
    });
  }

  async logout(): Promise<void> {
    await this.auth.signOut();
    await this.router.navigate(['/login']);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
