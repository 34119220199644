<table
  mat-table
  [dataSource]="dataSource$"
  matSort
  cdkDropList
  cdkDropListOrientation="horizontal"
  (cdkDropListDropped)="listDropped($event)"
>
  <ng-container matColumnDef="path">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Path</th>
    <td mat-cell *matCellDef="let item">{{ item.path }}</td>
  </ng-container>

  <ng-container matColumnDef="method">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Method</th>
    <td mat-cell *matCellDef="let item">{{ item.method }}</td>
  </ng-container>

  <ng-container matColumnDef="code">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Status code</th>
    <td mat-cell *matCellDef="let item">{{ item.code }}</td>
  </ng-container>

  <ng-container matColumnDef="firebaseId">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>User</th>
    <td mat-cell *matCellDef="let item">
      <app-user-avatar [firebaseUserId]="item.firebaseId"></app-user-avatar>
    </td>
  </ng-container>

  <ng-container matColumnDef="createdAt">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let item">{{ item.createdAt | date: 'medium' : 'UTC' }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="headings"></tr>
  <tr mat-row class="row" [class.hidden]="isLoading" *matRowDef="let row; columns: headings"></tr>

  <tr class="mat-row" *matNoDataRow [hidden]="isLoading">
    <td class="mat-cell" [attr.colspan]="headings.length">
      <span>No matching data.</span>
    </td>
  </tr>
</table>

<suvo-bi-skeleton-table-rows
  *ngIf="isLoading"
  [rowCount]="pageSize"
  [columnWidths]="[10, 10, 10]"
/>

<mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons />
