import { computed, Injectable, signal } from '@angular/core';
import { UsersService } from '../../users/service/users.service';
import { OVERVIEW_DEFAULT_PREFERENCES } from '../constants/overview.constants';
import { IOverviewPreferences, TOverviewTileKey } from '../interfaces/overview.interfaces';

@Injectable()
export class OverviewPreferencesService {
  private readonly _state = signal<IOverviewPreferences>(OVERVIEW_DEFAULT_PREFERENCES);

  constructor(private readonly userService: UsersService) {
    this.init();
  }

  private async init() {
    const userPreferences = (await this.userService.getUserSettings())?.overviewPreferences;

    if (userPreferences) {
      // Update the reactive state
      this._state.update((preferences) => {
        for (const [k, v] of Object.entries(userPreferences)) {
          // Anything not in the default preferences object (like `_id`) should be ignored
          if (OVERVIEW_DEFAULT_PREFERENCES[k] !== undefined) {
            preferences[k] = v;
          }
        }
        return preferences;
      });
    }
  }

  /** @returns The user's overview preferences as a reactive readonly signal. */
  get overviewPreferences() {
    return this._state.asReadonly();
  }

  /** @returns The active state of the overview tile as a reactive computed signal. */
  getTilePreference(tile: TOverviewTileKey) {
    // If it is not false, it is either true or undefined (meaning it's a new tile, or the user just hasn't updated their view prefs yet)
    // in either non-false case, we want to show the tile
    return computed(() => this._state()[tile] !== false);
  }

  /** Updates the user preferences and informs the server. */
  updateTilePreference(tile: TOverviewTileKey, state: boolean) {
    this._state.update((preferences) => {
      preferences[tile] = state;
      return preferences;
    });
    this.userService.patchUserSettingsOverviewPreferences(this._state());
  }
}
