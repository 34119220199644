<mat-sidenav-container class="sidenav-container" autosize>
  <mat-sidenav-content class="middle-page">
    <div class="outlet-padding">
      <ng-content select="[mainContent]"></ng-content>
    </div>
  </mat-sidenav-content>

  <mat-sidenav
    [opened]="true"
    class="left-sidenav"
    #linksSidenav="matSidenav"
    autoFocus="false"
    fixedInViewport="true"
    [mode]="linksSidenavMode"
  >
    <ng-content select="[leftSidenav]"></ng-content>
  </mat-sidenav>
</mat-sidenav-container>
