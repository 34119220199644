import { DateTime } from 'luxon';
import { Component, OnInit } from '@angular/core';
import { IStatsTileDefinition } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/dashboards/interfaces/tiles/definitions/stats-tile-definition.interface';
import { FilterConditionType, FilterOperatorType } from '@suvo-bi-lib';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UsersService } from '../../../users/service/users.service';

@Component({
  selector: 'app-my-samples-report',
  templateUrl: './my-samples-report.component.html',
  styleUrls: ['./my-samples-report.component.scss'],
})
export class MySamplesReportComponent implements OnInit {
  constructor(private userService: UsersService) {}

  tileDefinition: IStatsTileDefinition;

  unsubscribe$ = new Subject<void>();

  userId: string;

  async ngOnInit() {
    this.userId = (await this.userService.getMe())?._id;

    await this.prepare();
  }

  prepare() {
    if (this.userId) {
      let nowDateTime = DateTime.utc();
      let startOfMonth = nowDateTime.startOf('month');

      this.tileDefinition = {
        tileType: 'stats',
        title: 'Your samples',
        statDefinitions: [
          {
            title: 'Total active samples',
            recipe: {
              dataSource: 'samples',
              fullRoute: 'sample-reports',
              metric: 'count',
              filters: {
                name: 'Report Filters',
                query: {
                  op: FilterOperatorType.And,
                  rules: [
                    {
                      key: 'finalisedAt',
                      con: [
                        {
                          type: FilterConditionType.NotExists,
                        },
                      ],
                    },
                    {
                      key: 'userId',
                      eq: this.userId,
                      type: 'OBJECT_ID',
                    },
                  ],
                },
              },
            },
          },
        ],
        canView: true,
        backgroundColor: 'white',
      };
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
  }
}
